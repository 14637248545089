import { defineStore } from "pinia";

export const useSearchBarStore = defineStore("searchbar", {
  state: () => ({
    expanded: false,
    searchTerm: ``,
  }),
  actions: {
    isClicked() {
      return this.expanded;
    },
    expand() {
      this.expanded = true;
    },
    hide() {
      this.expanded = false;
    },
    clearSearchTerm() {
      this.searchTerm = "";
    },
  },
});
