import { createRouter, createWebHistory } from "vue-router";
import DashboardPage from "../views/dashboard/DashboardPage.vue";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/userStore.tsx";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    return new Promise((resolve) => {
      window.scrollTo(0, 0);
      if (document.getElementById("main-content") !== null) {
        document.getElementById("main-content").scrollTop = 0;
      }
      resolve({ left: 0, top: 0 });
    });
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      meta: { title: "PlayTV" },
      component: DashboardPage,
    },
    {
      path: "/login",
      name: "login",
      meta: { title: "PlayTV || Login", onlyPublic: true },
      component: () => import("../views/login/LoginPage.vue"),
    },
    {
      path: "/error",
      name: "error",
      meta: { title: "PlayTV || Oops, error... " },
      component: () => import("../views/error/ErrorPage.vue"),
    },
    {
      path: "/setup/account",
      name: "SetupAccount",
      meta: { title: "PlayTV || Account Setup", onlyPrivate: true },
      component: () => import("@/views/account-setup/AccountSetupPage.vue"),
    },
    {
      path: "/setup/terms-and-conditions",
      name: "AcceptTermsAndConditions",
      meta: { title: "PlayTV || Terms And Conditions", onlyPrivate: true },
      component: () => import("../views/policy-setup/PolicySetupPage.vue"),
    },
    {
      path: "/create-video",
      name: "CreateVideo",
      meta: { title: "PlayTV || Create Video", onlyPrivate: true },
      component: () => import("../views/upload-video/UploadVideoPage.vue"),
    },
    {
      path: "/policy/terms-and-conditions",
      name: "TermsAndConditionsPage",
      meta: { title: "PlayTV || Terms And Conditions" },
      component: () =>
        import(
          "../views/policies/terms-and-conditions/TermsAndConditionsPage.vue"
        ),
    },
    {
      path: "/policy/privacy",
      name: "PrivacyPage",
      meta: { title: "PlayTV || Privacy Policy" },
      component: () =>
        import("../views/policies/privacy/PrivacyPolicyPage.vue"),
    },
    {
      path: "/policy/community-guidelines",
      name: "CommunityGuidelines",
      meta: { title: "PlayTV || Community Guidelines" },
      component: () =>
        import(
          "@/views/policies/community-guidelines/CommunityGuidelinesPage.vue"
        ),
    },
    {
      path: "/account",
      name: "AccountSettingsPage",
      meta: { title: "PlayTV || Account", onlyPrivate: true },
      component: () =>
        import("@/views/account-settings/AccountSettingsPage.vue"),
    },
    {
      path: "/account/details",
      name: "AccountDetailsPage",
      meta: { title: "PlayTV || Account Details", onlyPrivate: true },
      component: () =>
        import("@/views/account-settings/details/AccountDetailsPage.vue"),
    },
    {
      path: "/account/mutes-and-blocks",
      name: "AccountMutesAndBlocksPage",
      meta: { onlyPrivate: true },
      component: () =>
        import(
          "@/views/account-settings/mutes-and-blocks/AccountMutesAndBlocksPage.vue"
        ),
    },
    {
      path: "/account/notifications",
      name: "AccountNotificationsPage",
      meta: { title: "PlayTV || Notifications", onlyPrivate: true },
      component: () =>
        import(
          "@/views/account-settings/notifications/AccountNotificationsPage.vue"
        ),
    },
    {
      path: "/account/preferences",
      name: "AccountPreferencesPage",
      meta: { title: "PlayTV || Preferences", onlyPrivate: true },
      component: () =>
        import(
          "@/views/account-settings/preferences/AccountPreferencesPage.vue"
        ),
    },
    {
      path: "/account/violations",
      name: "AccountViolationsPage",
      meta: { title: "PlayTV || Violations", onlyPrivate: true },
      component: () =>
        import("@/views/account-settings/violations/AccountViolationsPage.vue"),
    },
    {
      path: "/logout",
      name: "Logout",
      meta: { title: "PlayTV || Logging out...", onlyPrivate: true },
      component: () => import("../views/logout/LogoutPage.vue"),
    },
    {
      path: "/v/:videoId",
      name: "Video",
      meta: { title: "PlayTV" },
      component: () => import("../views/video/VideoPage.vue"),
    },
    {
      path: "/login-link/:token",
      name: "LoginLink",
      meta: { title: "PlayTV || Login", onlyPublic: true },
      component: () => import("../views/login-link/LoginLinkLandingPage.vue"),
    },
    {
      path: "/v/:videoId/edit",
      name: "EditVideo",
      meta: { title: "PlayTV || Edit Video", onlyPrivate: true },
      component: () => import("../views/edit-video/EditVideoPage.vue"),
    },
    {
      path: "/recent-videos",
      name: "RecentVideos",
      meta: { title: "PlayTV || Recent Videos" },
      component: () =>
        import("../views/recently-added-videos/RecentVideosPage.vue"),
    },
    {
      path: "/search",
      name: "SearchResultsPage",
      meta: { title: "PlayTV || Search" },
      component: () =>
        import("../views/search/SearchResultsPage.vue"),
    },
    {
      path: "/search/bursts",
      name: "SearchBurstsPage",
      meta: { title: "PlayTV || Search Bursts" },
      component: () =>
        import("../views/search/bursts/SearchBurstsPage.vue"),
    },
    {
      path: "/search/channels",
      name: "SearchChannelsPage",
      meta: { title: "PlayTV || Search Channels" },
      component: () =>
        import("../views/search/channels/SearchChannelsPage.vue"),
    },
    {
      path: "/search/hashtags",
      name: "SearchHashtagsPage",
      meta: { title: "PlayTV || Search Hashtags" },
      component: () =>
        import("../views/search/hashtags/SearchHashtagsPage.vue"),
    },
    {
      path: "/search/videos",
      name: "SearchVideosPage",
      meta: { title: "PlayTV || Search Videos" },
      component: () =>
        import("../views/search/videos/SearchVideosPage.vue"),
    },
    {
      path: "/trending-videos",
      name: "TrendingVideos",
      meta: { title: "PlayTV || Trending Videos" },
      component: () =>
        import("../views/trending-videos/TrendingVideosPage.vue"),
    },
    {
      path: "/c/:username",
      name: "ChannelPage",
      meta: { title: "PlayTV" },
      component: () => import("../views/channel/ChannelPage.vue"),
    },
    {
      path: "/c/:username/edit",
      name: "EditChannelPage",
      meta: { title: "PlayTV || Edit Channel", onlyPrivate: true },
      component: () => import("../views/edit-channel/EditChannelPage.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/",
    },
  ],
});

const redirectToLogin = (next) => {
  next({ path: `/login?redirect=${window.location.pathname}` });
};

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const userStore = useUserStore();

  const authenticated = authStore.authenticated;
  const accountSetup = userStore.accountSetup;
  const loading = userStore.loading;
  const acceptTermsOfService = userStore.acceptTermsOfService;

  if (!loading && authenticated && acceptTermsOfService === false) {
    if (to.name !== "AcceptTermsAndConditions") {
      next("/setup/terms-and-conditions");
    } else {
      if (to.meta.title) {
        document.title = to.meta.title;
      }
      next();
    }

    return;
  }

  if (!loading && authenticated && accountSetup === false) {
    if (to.name !== "SetupAccount") {
      next("/setup/account");
    } else {
      if (to.meta.title) {
        document.title = to.meta.title;
      }
      next();
    }

    return;
  }

  if (to.meta.onlyPrivate === true && !authenticated) {
    redirectToLogin(next);
    return;
  }

  if (to.meta.onlyPublic === true && authenticated) {
    next("/");
    return;
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

router.onError((error, to) => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    window.location.href = to.fullPath;
  }
});

// iOS PWA specific handling
if ("scrollRestoration" in history) {
  history.scrollRestoration = "manual";
}

export default router;
