<template>
  <div class="d-inline-flex position-relative typeahead-container">
    <img
      src="../../assets/playtv/icons/caret_left_icon.svg"
      alt="back"
      class="search-back-button"
      @click="backSearchBar()"
      v-if="isMobileView"
    />
    <input
      type="search"
      name="search-video"
      placeholder="Search"
      autocomplete="off"
      v-model="searchBarStore.searchTerm"
    />
    <div>
      <div v-show="searchBarStore.searchTerm !== ''">
        <button class="clear-icon" @click="eraseSearchTerm()"></button>
      </div>
    </div>
    <div class="search-icon"></div>
  </div>
</template>
<script setup>
import { useSearchBarStore } from "@/stores/searchBarStore.js";

const { isMobileView } = defineProps({ isMobileView: Boolean });
const searchBarStore = useSearchBarStore();
const eraseSearchTerm = () => {
  searchBarStore.clearSearchTerm();
};
const backSearchBar = () => {
  if (searchBarStore.isClicked()) {
    searchBarStore.hide();
  } else {
    searchBarStore.expand();
  }
};
</script>
<style>
.typeahead-container {
  font-size: 14px;
  margin: 0 10px;
  display: flex;

  input {
    border-color: #737373;
    border-radius: 20px 0px 0px 20px;
    border-width: 1px 0px 1px 1px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 20px 0;
    flex-grow: 1;
    transition: box-shadow 0.3s ease, width 0.2s ease;
    background-color: #000;
    color: #fff;
    width: 95%;
    ::placeholder {
      color: #a3a3a3;
      opacity: 1; /* Firefox */
    }
    ::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #a3a3a3;
    }
  }

  .search-icon {
    border-color: #737373;
    border-radius: 0px 20px 20px 0px;
    border-width: 1px 1px 1px 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 20px 0;
    flex-grow: 1;
    transition: box-shadow 0.3s ease, width 0.2s ease;
    min-width: 36px;
    width: 5%;
    background-position: center;
    background-image: url("/src/assets/playtv/icons/search_icon.svg");
    background-repeat: no-repeat;
    background-size: 24px;
  }

  .clear-icon {
    position: absolute;
    top: 17px;
    padding: 8px 9px;
    color: #a3a3a3;
    transition: 0.3s;
    background-position: center;
    background-image: url("/src/assets/playtv/icons/close_icon.svg");
    background-repeat: no-repeat;
    background-size: 24px;
    transform: translateX(-130%);
    cursor: pointer;
  }
}
</style>
